@import "../../assets/styles/utils";

.ny-banner{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @include res(height,100vh,(md:600px,sm:400px,xs:300px));
    @include res(padding-top,$header-height-base,$header-height-ratio);
    @include res(padding-left,120px,10 / 120);
    @include res(padding-right,120px,10 / 120);
    @include res(padding-bottom,120px,0 / 120);
    @include res(margin-bottom,140px, 20  / 140);
    .banner-img{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        b{
            display: block;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .banner-title{
        position: absolute;
        bottom: 0;
        z-index: 1;
        box-sizing: border-box;
        background-color: rgba(197,19,51,.9);
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        color: #fff;
        flex-direction: column;
        font-family: 'roboto-b';
        text-transform: uppercase;
        line-height: .9;
        @include res(font-size,60px, 30 / 60);
        @include res(height,100%,(xs:auto));
        @include res(left,240px, 10 / 240);
        @include res(width,27%,(mmd:32%,xs:calc(100% - 20px)));
        @include res(padding-bottom,160px,20 / 160);
        @include res(padding-left,90px, 10 / 90);
        @include res(padding-right,90px, 10 / 90);
        @include res(padding-top,20px, 20 / 20);
        &::before{
            content: '';
            display: block;
            height: 1px;
            background-color: #fff;
            @include res(margin-bottom,30px, 15 / 30);
            @include res(width,100%);
        }
    }
}