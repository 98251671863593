@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto-l';
  src: url('../fonts/Roboto-Light.woff') format('woff'),
    url('../fonts/Roboto-Light.ttf') format('truetype'),
    url('../fonts/Roboto-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto-b';
  src: url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.ttf') format('truetype'),
    url('../fonts/Roboto-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'roboto-l', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.pd120{
  @include res(padding-left,120px, 10 / 120);
  @include res(padding-right,120px, 10 / 120);
}
.cover-img{
  display: block;
  width: 100%;
  height: auto;
}

//查看更多按钮
.common-more{
  display: inline-block;
  position: relative;
  border: 1px solid $color-main;
  color: $color-main;
  border-radius: 50px;
  white-space: nowrap;
  transition: all .3s;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  @include res(padding-top,25px, 12 / 25);
  @include res(padding-bottom,25px, 12 / 25);
  @include res(padding-left,70px, 30 / 70);
  @include res(padding-right,70px, 30 / 70);
  &.white{
    border: 1px solid #fff;
    color: #fff;
    &::after{
      background-color: #fff;
    }
    &:hover{
      color: $color-main;
    }
  }
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 0;
    background-color: $color-main;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all .3s $anime-bezier;
    z-index: -1;
  }
  &:hover{
    color: #fff;
    &::after{
      height: 100%;
    }
  }
}

//新闻列表
.news-list{
  // @include res(margin-bottom,50px, 30 / 50);
  ul{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex);
    li{
      flex-shrink: 0;
      background-color: $color-main-bg;
      @include res(width,28.5%,(md:31%,xs:100%));
      @include res(margin-bottom,95px, 10 / 95);
      &:hover{
        .item-img{
          b{
            transform: scale(1.1);
          }
        }
      }
      .item-img{
        position: relative;
        overflow: hidden;
        img{
          display: block;
          width: 100%;
          height: auto;
          opacity: 0;
        }
        b{
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-position: center;
          background-size: cover;
          transition: all .3s;
        }
      }
      .item-text{
        @include res(padding-top,60px, 20 / 60);
        @include res(padding-bottom,60px, 20 / 60);
        @include res(padding-left,35px, 15 / 35);
        @include res(padding-right,35px, 15 / 35);
        .title{
          display: -webkit-box !important;  
          overflow: hidden;  
          text-overflow: ellipsis;  
          word-break: break-all;  
          -webkit-box-orient: vertical;  
          -webkit-line-clamp: 2;
          color: #000;
          font-family: 'roboto-b';
          @include res(font-size,26px, 18 / 26);
          @include res(margin-bottom,25px, 15 / 25);
        }
        .desc{
          display: -webkit-box !important;  
          overflow: hidden;  
          text-overflow: ellipsis;  
          word-break: break-all;  
          -webkit-box-orient: vertical;  
          -webkit-line-clamp: 3;
          line-height: 1.4;
        }
      }
    }
    .placeholder{
      @include res(width,28.5%,(md:31%,xs:100%));
    }
  }
}

//分页
.page{
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(margin-top,0, (xs:20px));
  @include res(margin-bottom,100px, 30 / 100);
  a{
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $color-main-bg;
    transition: all .3s;
    @include res(margin,3px, 2 / 3);
    @include res(width,46px, 36 / 46);
    @include res(height,46px, 36 / 46);
    &:hover{
      color: $color-main;
    }
    &.active{
      background-color: $color-main;
      color: #fff;
    }
  }
}


