@import "../../assets/styles/utils";

.header{
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(height,$header-height-base,$header-height-ratio);
    @include res(padding-left,150px, 10 / 150);
    @include res(padding-right,128px, 60 / 128);
    .left-logo{
        height: 50%;
        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }
    .global{
        font-family: 'roboto-b';
        text-transform: uppercase;
        line-height: 2;
        position: relative;
        @include res(font-size,14px, 12 / 14);
        @include res(margin-top,0px,(md:10px));
        @include res(margin-right,0px,(md:80px));
        &::after{
            content: '';
            width: 1px;
            background-color: #e5e5e5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include res(display,none,(md:block));
            @include res(right,-20px, -10 / -20);
            @include res(height,20px, 18 / 20);
        }
        a{
            display: flex;
            align-items: center;
            
            // &:hover{
            //     color: $color-main;
            // }
            .iconfont{
                @include res(margin-right,10px, 5 / 10);
                @include res(font-size,28px, 20 / 28);
                transition: all .3s;
                &:hover {
                    color: #c51333;
                }
            }
            
        }
    }
    .right-nav {
        margin-left: auto;
        a {
            font-family: Roboto;
            color: #000000;
	        opacity: 0.8;
            margin-right: 50px;
            @include res(font-size,20px, 12 / 20);
            @include res(margin-right,50px, -50 / 50);
            transition: all .5s;
            &:hover {
                color: #c51333;
            }
            &.active {
                color: #c51333;
            }
        }
        @include res(display,block,(md:none));

    }
}

.open-menu{
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 102;
    cursor: pointer;
    @include res(top, 54px, 28 / 54);
    @include res(right, 120px, 10 / 120);
    @include res(font-size,18px, 12 / 18);
    @include res(display,none, (md:flex));
    &.active{
        .open-menu-text{
            span{
                &:first-child{
                    display: none;
                }
                &:last-child{
                    display: inline-block;
                }
            }
        }
    }
    .open-menu-text{
        font-family: 'roboto-b';
        color: $color-main;
        text-transform: uppercase;
        margin-right: 10px;
        span{
            &:last-child{
                display: none;
            }
        }
    }
    .hamburger{
        @include res(width,58px, 30 / 58);
        &>div{
            background-color: transparent;
            &::before{
                @include res(top,-6px,(xs:-4px));
            }
            &::after{
                @include res(bottom,-6px,(xs:-4px));
            }
        }
        &.active{
            &>div{
                @include res(transform,rotate(15deg),(xs:rotate(25deg)));
                &::before{
                    opacity: 1;
                }
                &::after{
                    @include res(transform,rotate(-30deg),(xs:rotate(-50deg)));
                }
            }
        }
    }
    .hamburger>div, .hamburger>div:after, .hamburger>div:before{
        @include res(width,58px, 30 / 58);
    }
}

.fixed-menu{
    position: fixed;
    height: 0;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 101;
    &.active{
        .menu-left{
            transform: translateY(-100%);
        }
        .menu-right{
            @include res(transform,translateY(100%),(xs:translateX(-100%)));
        }
    }
    .menu-left{
        height: 100vh;
        flex-shrink: 0;
        box-sizing: border-box;
        background: url(./images/menu_bg.png) no-repeat center $color-main;
        background-size: cover;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        color: #fff;
        transition: transform .5s $anime-bezier;
        position: fixed;
        top: 100%;
        left: 0;
        @include res(display,flex,(xs:none));
        @include res(width,50%,(xs:100%));
        @include res(padding-top,140px, 80 / 140);
        @include res(padding-bottom,80px, 20 / 80);
        @include res(padding-left,120px, 10 / 120);
        @include res(padding-right,100px, 10 / 100);
        h1{
            font-family: 'roboto-b';
            text-transform: uppercase;
            transition: all .3s;
            // transform: translateY(-50px);
            // opacity: 0;
            // transition-delay: .4s;
            @include res(font-size,76px, 30 / 76);
        }
        h2{
            transition: all .3s;
            // transform: translateY(50px);
            // opacity: 0;
            // transition-delay: .4s;
            @include res(line-height,1.6, 1.2 / 1.6);
            @include res(font-size,38px, 16 / 38);
            &::before{
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                @include res(width,130px, 80 / 130);
                @include res(margin-bottom,45px, 15 / 45);
            }
        }
    }
    .menu-right{
        height: 100vh;
        flex-shrink: 0;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        transition: transform .5s $anime-bezier;
        position: fixed;
        @include res(width,50%,(xs:100%));
        @include res(bottom,100%,(xs:0));
        @include res(right,0,(xs:auto));
        @include res(left,auto,(xs:100%));
        @include res(align-items,flex-end,(xs:flex-start));
        @include res(padding-top,140px, 80 / 140);
        @include res(padding-bottom,60px, 20 / 60);
        @include res(padding-left,100px, 20 / 100);
        @include res(padding-right,100px, 10 / 100);
        a{
            display: block;
            font-family: 'roboto';
            color: #000;
            transition: all .3s;
            text-transform: uppercase;
            @include res(margin-top,30px, 20 / 30);
            @include res(font-size,36px, 18 / 36);
            &:hover,&.active{
                color: $color-main;
            }
        }
    }
}
@media (max-width: 768px) {
    .header .global {
        margin-right: 50px;
    }
}
