.ny-banner {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 120px;
  margin-bottom: 140px;
}

@media (max-width: 1024px) {
  .ny-banner {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    height: 300px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-top: 108px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-top: 96px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-top: 84px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-top: 72px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-left: 98px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-left: 76px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-left: 54px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-right: 98px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-right: 76px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-right: 54px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-bottom: 96px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-bottom: 72px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-bottom: 48px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    margin-bottom: 116px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    margin-bottom: 92px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    margin-bottom: 68px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    margin-bottom: 44px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    margin-bottom: 20px;
  }
}

.ny-banner .banner-img {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ny-banner .banner-img b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.ny-banner .banner-title {
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  background-color: rgba(197, 19, 51, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: #fff;
  flex-direction: column;
  font-family: 'roboto-b';
  text-transform: uppercase;
  line-height: .9;
  font-size: 60px;
  height: 100%;
  left: 240px;
  width: 27%;
  padding-bottom: 160px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 20px;
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    left: 194px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    left: 148px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    left: 102px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    left: 56px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    left: 10px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    width: calc(100% - 20px);
  }
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    padding-bottom: 132px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    padding-bottom: 104px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    padding-bottom: 76px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    padding-left: 74px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    padding-left: 58px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    padding-left: 42px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    padding-left: 26px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    padding-right: 74px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    padding-right: 58px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    padding-right: 42px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    padding-right: 26px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .banner-title {
    padding-top: 20px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title {
    padding-top: 20px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title {
    padding-top: 20px;
  }
}

.ny-banner .banner-title::before {
  content: '';
  display: block;
  height: 1px;
  background-color: #fff;
  margin-bottom: 30px;
  width: 100%;
}

@media (max-width: 1600px) {
  .ny-banner .banner-title::before {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .banner-title::before {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .banner-title::before {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .ny-banner .banner-title::before {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .ny-banner .banner-title::before {
    margin-bottom: 15px;
  }
}
