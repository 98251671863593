@import "../../assets/styles/utils";

footer{
    background-color: $color-main;
    .footer{
        color: #fff;
        text-align: center;
        @include res(padding-top,120px,20 / 120);
        @include res(padding-bottom,100px,20 / 100);
        .t1{
            text-transform: uppercase;
            @include res(font-size,30px, 16 / 30);
            &::after{
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                margin: 0 auto;
                @include res(margin-top,30px, 15 / 30);
                @include res(margin-bottom,60px, 20 / 60);
                @include res(width,132px,100 / 132);
            }
        }
        .t2{
            font-family: 'roboto-b';
            text-transform: uppercase;
            white-space: nowrap;
            @include res(font-size,100px, 32 / 100);
        }
        .t3{
            @include res(font-size,22px, 14 / 22);
            div{
                @include res(margin-top,30px, 10 / 30);
                &:first-child{
                    span{
                        @include res(display,inline-block,(xs:block));
                        &:first-child{
                            @include res(margin-right,30px,(xs:0));
                            @include res(margin-bottom,0px,(xs:10px));
                        }
                    }
                }
                &:last-child{
                    @include res(margin-top,45px, 15 / 45);
                    span{
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }
    }
    .copyright{
        border-top: 1px solid rgba(255,255,255,.5);
        align-items: center;
        justify-content: space-between;
        color: rgba(255,255,255,.5);
        text-align: center;
        @include res(display,flex,(xs:block));
        @include res(padding-top,30px, 10 / 30);
        @include res(padding-bottom,30px, 10 / 30);
        .right{
            align-items: center;
            @include res(display,flex,(xs:block));
            @include res(margin-top,0,(xs:5px));
            span{
                margin: 0 5px;
            }
            .site-map{
                @include res(display,null,(sm:none));
            }
        }
        a{
            transition: all .3s;
            &:hover{
                color: #fff;
            }
        }
    }
}